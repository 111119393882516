import { BaseService } from "../portalServices/baseService";
import firebase from "firebase/app";
import { Backend } from "../Backend";
import { PartnersEnum } from "../enums";
import { castVoucherLogData } from "../Backend/helper";

export class VouchersService extends BaseService {
  constructor(backend:Backend) {
    super();
    this.backend = backend,
    this.apiURL += "/vouchers/redeem";
  }

  private backend:Backend;
  private onListVouchersLogsSnapshotCallback!: Function;
  public vouchersLogs : Array<any> = []; 

  onListVouchersLogsChange(callback: Function) {
    this.onListVouchersLogsSnapshotCallback = callback;
  }

  addVoucher = async (voucherKey:string, mobileNumber: string, partnerId: string) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const body = {  "mobileNumber": mobileNumber, 
                      "promocode": voucherKey, 
                      "partnerId": partnerId };
      
      const requestOptions = await this.postRequestOptions(body);
      await fetch(this.apiURL, requestOptions).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      }).then((responseData) => {
        if (responseData.success) {
          return;
        } else {
          throw new Error((responseData.data.message) ? responseData.data.message : "Something went wrong");
        }
      }).catch((error) => {
        throw error;
      });
    }
  };

  /**
   * @param partnerId 
   * @returns allowed vouchers can be added for users by specific partner
   */
  getAllowedVouchersKeysForPartner = async (partnerId:string) => {
    const ref = this.backend.rtdb.ref("/promocodes");
    const allowedVouchersKeys:any = [];
    await ref.once("value", function(snapshot) {
      snapshot.forEach(function(promocodeSnapshot) {
        const promocode = promocodeSnapshot.val();
        if (promocode.allowedPartnersToRedeem && promocode.allowedPartnersToRedeem.includes(partnerId)) {
          allowedVouchersKeys.push(promocodeSnapshot.key);
        }
      });
    });
    return allowedVouchersKeys;
  }

  setVouchersLogs(updatedVouchersLogsList:Array<any>) {
    this.vouchersLogs = updatedVouchersLogsList;
    //Notifiy Listner if exist
    if (this.onListVouchersLogsSnapshotCallback) this.onListVouchersLogsSnapshotCallback([...this.vouchersLogs]); 
    // else console.log("fff");
  }

  getVouchersLogs = async (fromDate:string, toDate:string) => {
    this.setVouchersLogs([]);

    const startAt = fromDate ? new Date(fromDate) : null;
    const endAt = toDate ? new Date(new Date(toDate).getTime() + 60 * 60 * 24 * 1000) : null;

    const vouchersLogsPath = `${PartnersEnum.PARTNER_PATH}/${this.backend.vendorId}/${PartnersEnum.VOUCHERS_LOGS_PATH}`;
    this.backend.whereEqualCompoundQuery(
      vouchersLogsPath,
      false,
      null,
      {
        field: "timestamp",
        direction: "desc",
      },
      startAt,
      endAt
      )
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added" && change.doc.id) {
              const newData = {
                id: change.doc.id,
                ...castVoucherLogData(change.doc),
              };
              this.vouchersLogs.push(newData);
            }
            else if (change.type === "removed" && change.doc.id) {
              this.vouchersLogs = this.vouchersLogs.filter((aVoucherLog) => {
                return aVoucherLog.id !== change.doc.id;
              });
            }
            else if (change.type === "modified" && change.doc.id) {
              const objIndex = this.vouchersLogs.findIndex((aVoucherLog) => aVoucherLog.id === change.doc.id);
              this.vouchersLogs[objIndex] = { id: change.doc.id, ...castVoucherLogData(change.doc) };
            }
          });

          this.setVouchersLogs([...this.vouchersLogs]);
        });
  };
}