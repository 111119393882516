// eslint-disable-next-line
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grommet, Box, Form, FormField, TextInput, Button } from "grommet";

import { bringy } from "../../styles";
import { Authentication, Alert } from "../../_actions";
import { Backend } from "../../services/Backend";

export function ResetPassword({ backend }: { backend: Backend }) {
  const dispatch = useDispatch();
  const authentication = new Authentication();
  const alert = new Alert();

  const [credentials, setCredentials] = useState({
    currentPassword: null,
    newPassword: null,
  });

  const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, currentPassword: event.target.value });
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, newPassword: event.target.value });
  };

  const resetPassword = async () => {
    if (credentials.currentPassword && credentials.newPassword) {
      dispatch(
        authentication.resetPassword(
          backend.auth.currentUser.uid,
          credentials.currentPassword,
          credentials.newPassword,
        ),
      );
    } else {
      dispatch(alert.fail("Please fill form"));
    }
  };

  return (
    <Grommet full theme={bringy}>
      <Box fill align="center" justify="start" pad="small">
        <Box width="medium">
          <Form onSubmit={() => resetPassword()}>
            <FormField
              value={credentials.currentPassword}
              htmlFor="currentPassword"
              label="Current Password"
              onChange={(event) => handleCurrentPasswordChange(event)}
            >
              <TextInput type="password" id="currentPassword" placeholder="Enter Your Current Password" />
            </FormField>
            <FormField
              value={credentials.newPassword}
              htmlFor="newPassword"
              label="New Password"
              onChange={(event) => handleNewPasswordChange(event)}
            >
              <TextInput type="password" id="newPassword" placeholder="Enter Your New Password" />
            </FormField>
            <Box direction="row" justify="center">
              <Button type="submit" primary label="Reset" />
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  );
}
