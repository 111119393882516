// eslint-disable-next-line
import React from "react";
import { Nav, Anchor, Button } from "grommet";
import { Backend } from "../../../services/Backend";
import { history } from "../../../_helpers";

export function NavItem({ backend }: { backend: Backend }) {
  const handleLogout = () => {
    backend.signout();
  };

  const resetPassword = () => {
    history.push("/resetPassword");
  };

  return (
    <Nav align="center" flex="grow" direction="row" justify="end">
      <Anchor label={backend.auth.currentUser.uid} color="text" onClick={() => resetPassword()} />
      <Button label="Sign Out" onClick={() => handleLogout()} />
    </Nav>
  );
}
