import firebase from "firebase/app";
import { apiConstants } from "../../_constants";

/**
 * BaseService will contain common attributes/logic for backend services
 */
export class BaseService {
  apiURL = apiConstants.API_URI;

  private requestOptions = async () => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const defaultRequestOptions = {
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      method: "",
      body: "",
    };
    return defaultRequestOptions;
  };

  /**
   *
   * @param {Object} body | null - Optional
   * @return {Object} requestOptions
   */
  postRequestOptions = async (body:Object | null) => {
    const postOptions = await this.requestOptions();
    postOptions.method = "POST";
    postOptions.body = JSON.stringify(body);
    return postOptions;
  };
}
