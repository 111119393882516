import { store } from "../../_helpers";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import { Alert, PolicyAction } from "../../_actions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export class Backend {
  
  constructor() {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
      this.Persistence = firebase.auth.Auth.Persistence.SESSION;
      this.auth = firebase.auth();
      this.firestore = firebase.firestore();
      this.storage = firebase.storage();
      this.rtdb = firebase.database();
    }
  }

  public Persistence!: firebase.auth.Auth.Persistence;
  public auth!: firebase.auth.Auth;
  public firestore!: firebase.firestore.Firestore;
  public storage!: firebase.storage.Storage;
  public vendorId!: string;
  public rtdb: firebase.database.Database;

  signout() {
    const policyActions = new PolicyAction();
    const alert = new Alert();
    try {
      this.auth.signOut();
      store.dispatch(policyActions.resetPolicyState());
      sessionStorage.clear();
    } catch (error) {
      store.dispatch(alert.fail("Somthing went wrong"));
    }
  }

  whereEqualCompoundQuery(
    path: string,
    isGroupCollection = false,
    equalCondition: { [key: string]: any } = null,
    orderBy: {
      field: string;
      direction: "asc" | "desc";
    } = null,
    startAt: any = null,
    endAt: any = null,
  ) {
    try {
      let query = isGroupCollection ? this.firestore.collectionGroup(path) : this.firestore.collection(path);

      if (equalCondition)
        for (const item of Object.keys(equalCondition)) query = query.where(item, "==", equalCondition[item]);

      query = orderBy ? query.orderBy(orderBy.field, orderBy.direction) : query;

      query = startAt ? query.where("timestamp", ">=", startAt) : query;

      query = endAt ? query.where("timestamp", "<=", endAt) : query;

      return query;
    } catch (error) {
      console.error(error);
      throw new Error(
        `whereEqualCompoundQuery function failed for ${path} with conditions of ${JSON.stringify(equalCondition)} ..`,
      );
    }
  }

  fetch(path: string, id: string) {
    try {
      return this.firestore.collection(path).doc(id);
    } catch (error) {
      console.error(error);
      throw new Error(`get function failed for ${path} and id ${id}`);
    }
  }

  fetchAll(path: string) {
    try {
      return this.firestore.collection(path);
    } catch (error) {
      console.error(error);
      throw new Error(`get function failed for ${path}`);
    }
  }

  update(path: string, id: string, data: any) {
    try {
      return this.firestore.collection(path).doc(id).update(data);
    } catch (error) {
      console.error(error);
      throw new Error(`failed to update for ${path} and id ${id}`);
    }
  }

  remove(path: string, id: string, key: string) {
    try {
      return this.firestore
        .collection(path)
        .doc(id)
        .update({ [key]: firebase.firestore.FieldValue.delete() });
    } catch (error) {
      console.error(error);
      throw new Error(`failed to remove ${key} for id ${id}`);
    }
  }

  add(path: string, data: any) {
    try {
      return this.firestore.collection(path).add(data);
    } catch (error) {
      console.error(error);
      throw new Error(`failed to add data to ${path} with data ${JSON.stringify(data)}`);
    }
  }

  downloadURL(path: string) {
    try {
      return this.storage.refFromURL(path).getDownloadURL();
    } catch (error) {
      throw new Error(`failed to download url from ${path}`);
    }
  }
}
