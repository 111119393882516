/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../AuthProvider";
import { Sitewrapper } from "../Sitewrapper";

export function PrivateRoute({ component: Component, ...rest }) {
  const user = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user.currentUser ? (
          <Sitewrapper backend={rest.backend}>
            <Component {...props} backend={rest.backend} />
          </Sitewrapper>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}
