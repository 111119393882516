import { Backend, castApplicationData, castTimestamp } from "../Backend";
import { PartnersEnum } from "./../enums";

export class ApplicationsList {
  private constructor(backend: Backend) {
    this.backend = backend;
  }

  public static getInstance(backend: Backend): ApplicationsList {
    ApplicationsList.instance = new ApplicationsList(backend);
    return ApplicationsList.instance;
  }

  public applications: Array<any> = [];
  public statusList: Array<string>;
  public products: Array<any> = [];
  private static instance: ApplicationsList;
  private backend: Backend;
  private applicationPath: string = PartnersEnum.APPLICATION_PATH;
  private partnersPath: string = PartnersEnum.PARTNER_PATH;
  private applicationStatusPath: string = PartnersEnum.APPLICATION_STATUS_PATH;
  private onSnapshotCallback!: Function;

  public selectedProductStatusList: Array<any> = [];

  onSnapshot(callback: Function) {
    this.onSnapshotCallback = callback;
  }

  getProductStatusLabels (productId : string, onStatusListChanged:Function) {
    if (this.backend.vendorId && productId && productId.length) {
      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.applicationStatusPath}/${productId}/status`,
          false,
          null,
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {

            const docData = {
              id: change.doc.id,
              ...castTimestamp(change.doc),
            };

            if (change.type === "added") {
              this.selectedProductStatusList.push(docData);
            }
            else if (change.type === "removed") {
              this.selectedProductStatusList = this.selectedProductStatusList.filter((status) => status.id !== docData.id);
            }
            else if (change.type === "modified") {
              const objIndex = this.selectedProductStatusList.findIndex((status) => status.id === docData.id);
              this.selectedProductStatusList[objIndex] = docData;
            }
          });

          if (onStatusListChanged) {
            const statusLabels: any[] = [];
            this.selectedProductStatusList.forEach(element => {
              statusLabels.push(element.label);
            });
            onStatusListChanged(statusLabels);
          }
        });
    }
  }

  getProducts() {
    if (this.backend.vendorId) {
      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.applicationStatusPath}`,
          false,
          null,
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newData = {
                id: change.doc.id,
                ...castTimestamp(change.doc),
              };
              this.products.push(newData);
            }
            if (change.type === "removed") {
              this.products = this.products.filter((product) => product.id !== change.doc.id);
            }
            if (change.type === "modified") {
              const objIndex = this.products.findIndex((product) => product.id === change.doc.id);
              this.products[objIndex] = { ...castTimestamp(change.doc) };
            }
          });
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }

  get(startDate: string | null, endDate: string | null, product: string, status: string) {
    if (this.backend.vendorId) {
      const startAt = startDate ? new Date(startDate) : startDate;
      const endAt = endDate ? new Date(new Date(endDate).getTime() + 60 * 60 * 24 * 1000) : endDate;
      const filteringCondition = (): any => {
        if (product === "All Products") return null;
        if (status === "all") return { productId: product };
        return { currentStatus: status, productId: product };
      };

      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.applicationPath}`,
          false,
          filteringCondition(),
          {
            field: "timestamp",
            direction: "asc",
          },
          startAt,
          endAt,
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added" && change.doc.id) {
              const newData = {
                id: change.doc.id,
                ...castApplicationData(change.doc),
              };
              this.applications.push(newData);
            }
            if (change.type === "removed" && change.doc.id) {
              this.applications = this.applications.filter((application) => {
                return application.id !== change.doc.id;
              });
            }
            if (change.type === "modified" && change.doc.id) {
              const objIndex = this.applications.findIndex((application) => application.id === change.doc.id);
              this.applications[objIndex] = { id: change.doc.id, ...castApplicationData(change.doc) };
            }
          });
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }
}
