import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "grommet";
import { Download, View } from "grommet-icons";
import { IFileItemProps } from "../../../interfaces/Props";
import { Alert } from "../../../_actions";

export function FileItem({ fileName, filePath, downloadingFlag, downloadURL }: IFileItemProps) {
  const dispatch = useDispatch();
  const alert = new Alert();
  const [fileURL, setFileURL] = useState(null);

  useEffect(() => {
    getFileURL();
    // eslint-disable-next-line
  }, []);

  const getFileURL = async () => {
    try {
      const url = await downloadURL(filePath);
      setFileURL(url);
    } catch (error) {
      dispatch(alert.fail(`Failed to download ${fileName}. Please try later`));
    }
  };

  return (
    <Button
      disabled={fileURL ? false : true}
      href={fileURL}
      target={downloadingFlag ? "_self" : "_blank"}
      icon={downloadingFlag ? <Download /> : <View />}
      hoverIndicator
      label={downloadingFlag ? "Download" : `${fileName}`}
    />
  );
}
