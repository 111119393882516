import { Form, Box, Heading, FormField, Select, TextInput, Button } from "grommet";
import React from "react";

interface IStatusProps {
  defaultValue: string;
  statusOptions: Array<string>;
  notes?: string;
  handleStatusChange(value: string): any;
  handleNotesChange(event: React.ChangeEvent<HTMLInputElement>): any;
  submitStatus(): any;
  showLogs(): any;
}

export function PolicyStatusForm({
  defaultValue,
  notes,
  statusOptions,
  submitStatus,
  handleStatusChange,
  handleNotesChange,
  showLogs,
}: IStatusProps) {
  return (
    <Form onSubmit={() => submitStatus()}>
      <Box flex direction="row" align="center" gap="small">
        <Heading margin="none" level={4}>
          Status :
        </Heading>
        <FormField>
          <Select
            value={defaultValue}
            disabled={[0]}
            id="currentStatus"
            options={statusOptions}
            onChange={({ option }) => handleStatusChange(option)}
          />
        </FormField>

        <FormField>
          <TextInput
            id="notes"
            value={notes}
            placeholder="Add notes (optional)"
            onChange={(event) => handleNotesChange(event)}
          />
        </FormField>

        <Box direction="row" justify="center">
          <Button type="submit" primary label="Change" />
        </Box>
        <Button secondary label="View Logs" onClick={() => showLogs()} />
      </Box>
    </Form>
  );
}
