// eslint-disable-next-line
import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import { Login } from "./Components/Login";
import { NoMatching } from "./Components/NoMatching";

import { history } from "./_helpers";
import { routes } from "./Routes";
import { FirebaseContext } from "./services/Backend";
import { PrivateRoute } from "./Components/PrivateRoute";
import { AuthProvider } from "./Components/AuthProvider";
import { AlertBanner } from "./Components/AlertBanner";

export function App() {
  return (
    <div>
      <AlertBanner />
      <FirebaseContext.Consumer>
        {(backend) => (
          <AuthProvider backend={backend}>
            <Router history={history}>
              <Switch>
                {routes.map((route) => {
                  return (
                    <PrivateRoute
                      key={route.path}
                      exact
                      path={route.path}
                      backend={backend}
                      component={route.component}
                    />
                  );
                })}
                <Route path="/login" render={(props) => <Login {...props} backend={backend} />} />
                <Route component={NoMatching} />
              </Switch>
            </Router>
          </AuthProvider>
        )}
      </FirebaseContext.Consumer>
    </div>
  );
}
