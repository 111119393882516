import React from "react";
import { FormField, CheckBoxGroup, TextInput, Select, Box, Button, DateInput } from "grommet";
import { Trash } from "grommet-icons";

interface ICheckBoxStatus {
  label: string;
  id: string;
  name: string;
  options: Array<{
    label: any;
    id: any;
  }>;
  required: boolean;
}

interface ISelectStatus {
  label: string;
  id: string;
  name: string;
  options: Array<{
    label: any;
    id: any;
  }>;
  required: boolean;
}

interface ITextStatus {
  label: string;
  id: string;
  name: string;
  type: string;
  required: boolean;
}

interface IDateStatus {
  label: string;
  id: string;
  name: string;
  defaultValue?: string;
}

interface IAttachFileStatus {
  label: string;
  id: string;
  name: string;
  required: boolean;
  selectedFiles: Array<any>;
  chooseAttachment(event: any): any;
  removeAttachment(name: string): any;
}

export function CheckBoxStatus({ label, name, id, options, required }: ICheckBoxStatus) {
  const labels = options.map((option) => option.label);
  return (
    <FormField label={label} key={`${id}`} name={name} component={CheckBoxGroup} options={labels} required={required} />
  );
}

export function TextStatus({ label, id, name, type, required }: ITextStatus) {
  return (
    <FormField key={id} htmlFor={label} label={label}>
      <TextInput
        id={label}
        placeholder={`Please add ${label} ${required ? "" : "(optional)"}`}
        name={name}
        type={type}
        required={required}
      />
    </FormField>
  );
}

export function SelectStatus({ label, id, name, options }: ISelectStatus) {
  const labels = options.map((option) => option.label);
  return (
    <FormField key={id} htmlFor={label} label={label}>
      <Select id={label} name={name} placeholder={`Select ${name}`} options={labels} />
    </FormField>
  );
}

export function AttachFileStatus({
  label,
  name,
  selectedFiles,
  chooseAttachment,
  removeAttachment,
}: IAttachFileStatus) {
  return (
    <Box flex direction="column" align="start" pad={{ bottom: "small" }}>
      <label
        style={{
          border: "1px solid #ccc",
          borderColor: "brand",
          display: "inline-block",
          padding: "12px",
          cursor: "pointer",
        }}
      >
        <input
          multiple
          type="file"
          style={{ display: "none" }}
          id={name}
          accept=".pdf"
          onChange={(event) => chooseAttachment(event)}
        />
        Choose {label}
      </label>
      {selectedFiles.map((file: File, index) => {
        return (
          <Box key={index} flex direction="row" align="center">
            {file.name}
            <Button icon={<Trash />} hoverIndicator onClick={() => removeAttachment(file.name)} />
          </Box>
        );
      })}
    </Box>
  );
}

export function DateStatus({ label, id, name }: IDateStatus) {
  return (
    <FormField key={id} htmlFor={label} label={label}>
      <DateInput id={label} name={name} format="dd/mm/yyyy" />
    </FormField>
  );
}
