export const columns = [
  {
    search: true,
    sortable: false,
    // primary: true,
    property: "mobileNumber",
    header: "Mobile Number",
  },
  {
    search: true,
    sortable: false,
    // primary: true,
    property: "voucherId",
    header: "Voucher",
  },
  {
    search: false,
    sortable: false,
    primary: true,
    property: "voucherAdditionTime",
    header: "Addition time",
  }
];
