import { applicationConstants } from "../_constants";
import { IApplicationAction } from "../interfaces/Actions";
import { IApplicationState } from "../interfaces/State";

const initialApplicationState: IApplicationState = {
  applicationDateRange: [],
  applicationFilteringData: {
    product: { label: "All Products", id: "All Products" },
    status: "all",
  },
  sort: {
    property: "timestamp",
    direction: "asc",
  },
  status: null,
};

export function application(state: IApplicationState = initialApplicationState, action: IApplicationAction) {
  switch (action.type) {
    case applicationConstants.ADD_APPLICATION_DATE_RANGE:
      return {
        ...state,
        applicationDateRange: action.applicationDateRange,
      };
    case applicationConstants.ADD_APPLICATION_FILTERING_DATA:
      return {
        ...state,
        applicationFilteringData: action.applicationFilteringData,
      };
    case applicationConstants.ADD_APPLICATION_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case applicationConstants.ADD_APPLICATION_STATUS_LIST:
      return {
        ...state,
        status: {
          ...state.status,
          [action.status["productId"]]: action.status["list"],
        },
      };
    case applicationConstants.RESET_APPLICATION_STATE:
      return {
        ...state,
        applicationDateRange: [],
        applicationFilteringData: {
          product: { label: "All Products", id: "All Products" },
          status: "all",
        },
        sort: {
          property: "timestamp",
          direction: "asc",
        },
        status: null,
      };
    default:
      return state;
  }
}
