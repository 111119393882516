import { Home } from "../Components/Home";
import { PolicyList, PolicyPage } from "../Components/Policy";
import { ApplicationList, ApplicationPage } from "../Components/Application";
import { ResetPassword } from "../Components/ResetPassword";
import { ApplicationLogs } from "../Components/Application/ApplicationLogs";
import { PolicyLogs } from "../Components/Policy/PolicyLogs";
import { AddVoucherToUser } from "../Components/Vouchers/AddVoucherToUser/AddVoucherToUser";
import { AddedVouchersReport } from "../Components/Vouchers/AddedVouchersReport/AddedVouchersReport";

export const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/policies",
    component: PolicyList,
  },
  {
    path: "/policies/:policyId",
    component: PolicyPage,
  },
  {
    path: "/policies/:policyId/logs",
    component: PolicyLogs,
  },
  {
    path: "/applications",
    component: ApplicationList,
  },
  {
    path: "/applications/:applicationId",
    component: ApplicationPage,
  },
  {
    path: "/applications/:applicationId/logs",
    component: ApplicationLogs,
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
  },
  {
    path: "/addVoucherToUser",
    component: AddVoucherToUser,
  },
  {
    path: "/addedVouchersReport",
    component: AddedVouchersReport,
  }
];
