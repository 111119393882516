import { alertConstants } from "../_constants";
import { IAlertAction } from "../interfaces/Actions";
import { IAlertState } from "../interfaces/State";

const initialAlertState: any = {
  type: null,
  message: null,
};

export function alert(state: IAlertState = initialAlertState, action: IAlertAction) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: "alert-success",
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: "alert-danger",
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
