// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Box, Button, Form, FormField, Heading, Select, TextInput } from "grommet";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input-mobile";
import "react-phone-number-input/style.css";
import { VouchersService } from "../../../services/Vouchers/vouchersService";
import { Alert } from "../../../_actions";
import { Loading } from "../../Loading";
import "./AddVoucherToUser.css";
import { Backend } from "../../../services/Backend";

export function AddVoucherToUser(props: any) {

  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [availableVouchers, setAvailableVouchers] = useState([]);

  const backend:Backend = props.backend;
  const partnerId = backend.vendorId;

  const alert = new Alert();

  useEffect(() =>{
    getAvailableVouchers();
  }, []);

  const addVoucherToUser = async () => {
    if (phoneNumber && phoneNumber.length && isValidPhoneNumber(phoneNumber) && voucher.length) {
      if (!showLoading) {
        setShowLoading(true);
        try {
          const vouchersService = new VouchersService(backend);
          await vouchersService.addVoucher(voucher, phoneNumber, partnerId);
          setShowLoading(false);
          setPhoneNumber("");
          alert.pass("Adding '" + voucher + "' to " + phoneNumber + " is inprogress.");
        } catch (error:any) {
          alert.fail("Something went wrong");
          setShowLoading(false);
        }
      }
    } else {
      alert.fail("mobile number and voucher are required");
    }
  };

  const getAvailableVouchers = async () => {
    try {
      setShowLoading(true);
      const vouchersService = new VouchersService(backend);
      const response = await vouchersService.getAllowedVouchersKeysForPartner(partnerId);
      setAvailableVouchers(response);
      setShowLoading(false);
    } catch (error) {
      alert.fail("Something went wrong while loading vouchers");
      setShowLoading(false);
    }
  };


  return (
    <Box flex direction="column" justify="center" align="start" pad="small">
      <Box fill align="start" alignContent="center">
      <Heading level={3}>Add voucher to user</Heading>
        <Box width="large" border margin="large" pad="large">
          <Form>
            <FormField name="Mobile Number" label="Mobile Number">
              <div className="phone-input-wrapper">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  country="EG"
                  withCountryCallingCode={true}
                  international={true}
                  onChange={(value: string) => {
                    setPhoneNumber(value);
                  }}
                />
              </div>
            </FormField>
            <FormField name="Voucher" label="Voucher">
              <Select 
                id="Voucher"
                value={voucher}
                placeholder={"Select voucher"}
                options={availableVouchers} 
                onChange={({ option }) => {setVoucher(option);}}
                />
            </FormField>
            <Button  type="submit" primary label="Add" onClick={addVoucherToUser} disabled={showLoading}/>
            {showLoading && <Loading /> }
          </Form>
        </Box>
      </Box>
    </Box>
  );
}
