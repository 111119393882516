import { IStatusData } from "../../interfaces/Logs";
import { Backend, castPolicyData, castTimestamp } from "../Backend";
import { PartnersEnum } from "./../enums";

export class Policy {
  private constructor(backend: Backend) {
    this.backend = backend;
    this.bucketPath = process.env.REACT_APP_PARTNERTS_STORAGE_BUCKET;
    this.progress = {};
  }

  public static getInstance(backend: Backend): Policy {
    return Policy.instance ? this.instance : (this.instance = new Policy(backend));
  }

  private static instance: Policy;
  private backend: Backend;
  private onSnapshotCallback!: Function;
  private insurancePath: string = PartnersEnum.INSURANCE_PATH;
  private partnersPath: string = PartnersEnum.PARTNER_PATH;
  private statusLogPath: string = PartnersEnum.APPLICATION_STATUS_LOG_PATH;
  private bucketPath: string;
  public policy: any;
  public progress: { [key: string]: number };
  public logs: Array<any> = [];

  onSnapshot(callback: Function) {
    this.onSnapshotCallback = callback;
  }

  get(policyId: string) {
    if (this.backend.vendorId) {
      this.backend
        .fetch(`${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}`, policyId)
        .onSnapshot((snapshot) => {
          this.policy = {
            id: snapshot.id,
            exists: snapshot.exists,
            ...castPolicyData(snapshot),
          };
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }

  upload(policyId: string, userId: string, serialNumber: string, pdf: any) {
    const uploadTask = this.backend.storage
      .refFromURL(`${this.bucketPath}/${this.backend.vendorId}/policies/${userId}/${policyId}/${serialNumber}.pdf`)
      .put(pdf);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.progress[policyId] = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        if (this.onSnapshotCallback) this.onSnapshotCallback();
      },
      () => {
        // handle error condition
        this.progress[policyId] = 0;
        if (this.onSnapshotCallback) this.onSnapshotCallback();
      },
    );
  }

  async downloadPolicy(policyId: string, userId: string, serialNumber: string): Promise<string> {
    return await this.backend.downloadURL(
      `${this.bucketPath}/${this.backend.vendorId}/policies/${userId}/${policyId}/${serialNumber}.pdf`,
    );
  }

  async downloadFile(filePath: string): Promise<string> {
    return await this.backend.downloadURL(`${this.bucketPath}/${filePath}`);
  }

  async changeStatus(policyId: string, data: IStatusData) {
    if (this.backend.vendorId && data && policyId) {
      this.backend.update(`${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}`, policyId, {
        status: data.currentStatus,
      });

      this.setLog(policyId, data);
    }
  }

  async removePdf(policyId: string) {
    if (this.backend.vendorId && policyId) {
      return this.backend.remove(
        `${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}`,
        policyId,
        "policyPath",
      );
    }
  }

  async setLog(policyId: string, data: IStatusData) {
    if (this.backend.vendorId && data && policyId) {
      return this.backend.add(
        `${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}/${policyId}/${this.statusLogPath}`,
        { ...data },
      );
    }
  }

  getStatusLogs(policyId: string) {
    if (this.backend.vendorId) {
      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}/${policyId}/${this.statusLogPath}`,
          false,
          null,
          {
            field: "timestamp",
            direction: "asc",
          },
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newData = {
                id: change.doc.id,
                ...castTimestamp(change.doc),
              };
              this.logs.unshift(newData);
            }
            if (change.type === "removed") {
              this.logs = this.logs.filter((log) => {
                return log.id !== change.doc.id;
              });
            }
            if (change.type === "modified") {
              const objIndex = this.logs.findIndex((log) => log.id === change.doc.id);
              this.logs[objIndex] = { ...castTimestamp(change.doc) };
            }
          });
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }
}
