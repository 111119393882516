// eslint-disable-next-line
import React from "react";
import { Box } from "grommet";

export function Content({ children }: any) {
  return (
    <Box fill align="start" justify="center" direction="row" overflow="auto" width={{ min: "900px" }}>
      {children}
    </Box>
  );
}
