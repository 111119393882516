import React from "react";
import { Box, Heading } from "grommet";
import { IFileCardProps } from "../../../interfaces/Props";
import { FileItem } from "./../";

export function FileCard({ data, cardTitle, downloadingFlag, downloadURL }: IFileCardProps) {
  return (
    <Box flex direction="row" justify="start" align="center" gap="small">
      <Heading margin="none" level={4}>
        {cardTitle} :
      </Heading>
      {data.map((filePath, index) => {
        return (
          <FileItem
            key={index}
            fileName={`${cardTitle} ${index + 1}`}
            filePath={filePath}
            downloadingFlag={downloadingFlag}
            downloadURL={(path: string) => downloadURL(path)}
          />
        );
      })}
    </Box>
  );
}
