import { authenticationConstants } from "../_constants";
import { IAuthenticationAction } from "../interfaces/Actions";
import { IAuthenticationState } from "../interfaces/State";

const initialAuthenticationState: any = {
  token: null,
  profile: null,
};

export function authentication(
  state: IAuthenticationState = initialAuthenticationState,
  action: IAuthenticationAction,
) {
  switch (action.type) {
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case authenticationConstants.LOGIN_FAILURE:
      return {
        ...state,
        token: {},
      };
    default:
      return state;
  }
}
