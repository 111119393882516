import { Dispatch } from "redux";
import { Alert } from ".";
import { IAlertAction, IApplicationAction, ISortBy } from "../interfaces/Actions";
import { IProductObject } from "../interfaces/Props";
import { Application } from "../services/Application";
import { applicationConstants } from "../_constants";

export class ApplicationAction {
  public addDateRange = (applicationDateRange: Array<string>) => {
    return {
      type: applicationConstants.ADD_APPLICATION_DATE_RANGE,
      applicationDateRange,
    };
  };

  public addFilteringData = (applicationFilteringData: { product: IProductObject; status: string }) => {
    return {
      type: applicationConstants.ADD_APPLICATION_FILTERING_DATA,
      applicationFilteringData,
    };
  };

  public addSorting = (sort: ISortBy) => {
    return {
      type: applicationConstants.ADD_APPLICATION_SORT,
      sort,
    };
  };

  public resetPolicyState = () => {
    return {
      type: applicationConstants.RESET_APPLICATION_STATE,
    };
  };

  private addStatusList = (productId: string, statusList: Array<any>) => {
    return {
      type: applicationConstants.ADD_APPLICATION_STATUS_LIST,
      status: { productId, list: statusList },
    };
  };

  fetchProductStatusList = (productId: string, applicationInstance: Application) => {
    return async (dispatch: Dispatch<IApplicationAction | IAlertAction>) => {
      const alert = new Alert();
      try {
        const statusList = await applicationInstance.getStatusList(productId);
        dispatch(this.addStatusList(productId, statusList));
      } catch (fail) {
        dispatch(alert.fail("Failed to get status list"));
      }
    };
  };
}
