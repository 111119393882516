import axios from "axios";

interface IFetchRequest {
  success: boolean;
  data: any;
}

export const fetchRequest = (
  method: "GET" | "POST" | "DELETE" | "PUT",
  url: string,
  headers: any,
  body?: any,
): Promise<IFetchRequest> => {
  const result = axios({
    method,
    url,
    data: body,
    headers,
  }).then((response) => {
    return response.data;
  });
  return result;
};
