// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Box } from "grommet";
import { Backend } from "../../../services/Backend";
import { Application } from "../../../services/Application";
import { IMatchProps } from "../../../interfaces/Props";
import { IStatusData } from "../../../interfaces/Logs";
import { NoDataFound } from "../../NoDataFound";
import { LogCard } from "../../LogCard";

export function ApplicationLogs({ backend, match }: { backend: Backend; match: IMatchProps }) {
  const {
    params: { applicationId },
  } = match;
  const [logs, setLogs] = useState<Array<IStatusData>>([]);
  const applicationInstance = Application.getInstance(backend);

  useEffect(() => {
    applicationInstance.onSnapshot(() => setLogs([...applicationInstance.logs]));
    applicationInstance.getStatusLogs(applicationId);
    // eslint-disable-next-line
  }, []);

  const renderData = () => {
    if (logs.length > 0) {
      return (
        <Box flex justify="around" direction="column" pad="small">
          {logs.map((log: IStatusData, index: number) => {
            return <LogCard key={index} log={log} />;
          })}
        </Box>
      );
    } else return <NoDataFound data={`No logs available for application: ${applicationId}`} />;
  };

  return renderData();
}
