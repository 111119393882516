// eslint-disable-next-line
import React from "react";
import { Box } from "grommet";
import { INoDataProps } from "../../interfaces/Props";

export function NoDataFound({ data }: INoDataProps) {
  return (
    <Box flex direction="column" justify="center" align="center">
      <p>{data}</p>
    </Box>
  );
}
