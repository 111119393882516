// eslint-disable-next-line
import React from "react";
import { Box, Image } from "grommet";
import { LogoHeading } from "./Heading";
import logo from "../../Assets/bringy.png";

export function Logo() {
  return (
    <Box align="center" justify="center" direction="row" gap="medium">
      <Image src={logo} fit="contain" />
      <LogoHeading />
    </Box>
  );
}
