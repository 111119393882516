// eslint-disable-next-line
import React from "react";
import { Heading } from "grommet";

export function LogoHeading() {
  return (
    <Heading level="2" margin={{ horizontal: "none", vertical: "xsmall" }}>
      Partner Portal
    </Heading>
  );
}
