export const colors = {
  brand: {
    dark: "#dc0000",
    light: "#dc0000",
  },

  "table-header": {
    dark: "light-1",
    light: "light-1",
  },

  background: {
    dark: "#111111",
    light: "#FFFFFF",
  },

  "background-back": {
    dark: "#111111",
    light: "#EEEEEE",
  },

  "background-front": {
    dark: "#222222",
    light: "#FFFFFF",
  },

  "background-contrast": {
    dark: "#FFFFFF11",
    light: "#11111111",
  },

  text: {
    dark: "#EEEEEE",
    light: "#333333",
  },

  "text-strong": {
    dark: "#FFFFFF",
    light: "#000000",
  },

  "text-weak": {
    dark: "#CCCCCC",
    light: "#444444",
  },

  "text-xweak": {
    dark: "#999999",
    light: "#666666",
  },

  border: {
    dark: "#444444",
    light: "#CCCCCC",
  },

  control: "brand",
  "active-background": "background-contrast",
  "active-text": "text-strong",
  "selected-background": "brand",
  "selected-text": "text-strong",
  "status-critical": "#FF4040",
  "status-warning": "#FFAA15",
  "status-ok": "#00C781",
  "status-unknown": "#CCCCCC",
  "status-disabled": "#CCCCCC",
  "graph-0": "brand-green",
  "graph-1": "brand-purple",

  "brand-green": {
    dark: "#008100",
    light: "#008100",
  },

  "brand-green!": "",

  "brand-blue": {
    dark: "#0089E6",
    light: "#0089E6",
  },

  "brand-blue!": "",
  "brand-purple": {
    dark: "#CE20BC",
    light: "#CE20BC",
  },

  "brand-purple!": "",
  "graph-2": "status-warning",
};
