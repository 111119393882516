// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { IMatchProps } from "../../../interfaces/Props";
import { Backend } from "../../../services/Backend";
import { Application } from "../../../services/Application";
import { MotorApplicationPage } from "./Motor";
import { NoDataFound } from "../../NoDataFound";
import { ProductsTitle } from "../../../_helpers";

export function ApplicationPage({ backend, match }: { backend: Backend; match: IMatchProps }) {
  const {
    params: { applicationId },
  } = match;

  const [application, setPolicy] = useState<any>(null);
  const applicationInstance = Application.getInstance(backend);

  useEffect(() => {
    applicationInstance.onSnapshot(() => setPolicy(applicationInstance.application));
    applicationInstance.getData(applicationId);
    // eslint-disable-next-line
  }, []);

  const checkApplicationProduct = () => {
    if (application && application.exists) {
      switch (application.productId) {
        case ProductsTitle.MOTOR:
          return (
            <MotorApplicationPage
              application={application}
              applicationId={applicationId}
              applicationInstance={applicationInstance}
              backend={backend}
            />
          );
        default:
          return <NoDataFound data="No data available for this application" />;
      }
    } else return <NoDataFound data="No data available for this application" />;
  };

  return checkApplicationProduct();
}
