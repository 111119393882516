// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { IApplicationListStatusProps } from "../../../interfaces/Props";
import { SelectFilter } from "../../SelectFilter";

export function ApplicationListStatus({
  applicationInstance,
  status,
  product,
  handleFilteredData,
  applications,
}: IApplicationListStatusProps) {
  const [products, setProducts] = useState<Array<{ id: string; label: string; status: Array<string> }>>([]);
  const [statusList, setStatusList] = useState<Array<string>>([]);

  const [filteringData, setStatusProps] = useState({
    selectedProduct: product,
    selectedStatus: status,
  });

  const getStatusList = () => {
    setStatusList([]);
    applicationInstance.getProductStatusLabels(product.id, (updatedStatusList:Array<string>)=>{
      setStatusList(updatedStatusList);
    });
  };

  useEffect(() => {
    applicationInstance.getProducts();
    applicationInstance.onSnapshot(() => {
      setProducts([...applicationInstance.products]);
      const product = applicationInstance.products.find(
        (product) => product.label === filteringData.selectedProduct.label,
      );
      if (product) {
        getStatusList();
      }
    });
    // eslint-disable-next-line
  }, [applications]);

  const handleProductSelection = (productLabel: string) => {
    if (productLabel === "All Products") selectAllProducts();
    else selectSpecificProduct(productLabel);
  };

  const selectAllProducts = () => {
    setStatusProps({ selectedProduct: { label: "All Products", id: "All Products" }, selectedStatus: "all" });
    setStatusList([]);
    handleFilteredData({ label: "All Products", id: "All Products" }, "all");
  };

  const selectSpecificProduct = (productLabel: string) => {
    const product = products.find((product) => product.label === productLabel);
    setStatusProps({ selectedProduct: { label: product.label, id: product.id }, selectedStatus: "all" });
    getStatusList();
    handleFilteredData({ label: product.label, id: product.id }, "all");
  };

  const handleStatusSelection = (selectedStatus: string) => {
    setStatusProps({ ...filteringData, selectedStatus });
    const product = products.find((product) => product.label === filteringData.selectedProduct.label);
    handleFilteredData({ label: product.label, id: product.id }, selectedStatus);
  };

  const getLabels = (): Array<any> => {
    const labels = products.map((product: any) => product.label);
    return labels;
  };

  return (
    <Box flex align="center" justify="between" direction="row" gap="large">
      <Box flex align="center" justify="between" direction="row" gap="small">
        <Box background={{ color: "brand" }} pad="xsmall">
          <Text>Product</Text>
        </Box>
        <SelectFilter
          defaultValue={filteringData.selectedProduct.label}
          options={products.length > 0 ? ["All Products", ...getLabels()] : [filteringData.selectedProduct.label]}
          handleValueSelection={(productLabel: string) => handleProductSelection(productLabel)}
          placeholder={"Select Product"}
        />
      </Box>
      {statusList.length > 0 ? (
        <Box flex align="center" justify="between" direction="row" gap="small">
          <Box background={{ color: "brand" }} pad="xsmall">
            <Text>Status</Text>
          </Box>
          <SelectFilter
            defaultValue={filteringData.selectedStatus}
            options={statusList.length > 0 ? ["all", ...statusList] : [filteringData.selectedStatus]}
            handleValueSelection={(status: string) => handleStatusSelection(status)}
            placeholder={"Select Status"}
          />
        </Box>
      ) : null}
    </Box>
  );
}
