import { IStatusData } from "../../interfaces/Logs";
import { Backend, castApplicationData, castTimestamp } from "../Backend";
import { PartnersEnum } from "./../enums";

export class Application {
  private constructor(backend: Backend) {
    this.backend = backend;
    this.bucketPath = process.env.REACT_APP_PARTNERTS_STORAGE_BUCKET;
  }

  public static getInstance(backend: Backend): Application {
    this.instance = new Application(backend);
    return this.instance;
  }

  private static instance: Application;
  private backend: Backend;
  private onSnapshotCallback!: Function;
  private applicationPath: string = PartnersEnum.APPLICATION_PATH;
  private applicationStatusPath: string = PartnersEnum.APPLICATION_STATUS_PATH;
  private partnersPath: string = PartnersEnum.PARTNER_PATH;
  private statusLogPath: string = PartnersEnum.APPLICATION_STATUS_LOG_PATH;
  private bucketPath: string;
  public application: any;
  public logs: Array<any> = [];

  onSnapshot(callback: Function) {
    this.onSnapshotCallback = callback;
  }

  getData(applicationId: string) {
    if (this.backend.vendorId) {
      this.backend
        .fetch(`${this.partnersPath}/${this.backend.vendorId}/${this.applicationPath}`, applicationId)
        .onSnapshot((snapshot) => {
          this.application = {
            id: snapshot.id,
            exists: snapshot.exists,
            ...castApplicationData(snapshot),
          };
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }

  async getStatusList(productId: string) {
    if (this.backend.vendorId && productId) {
      const ref = await this.backend
        .fetchAll(`${this.partnersPath}/${this.backend.vendorId}/${this.applicationStatusPath}/${productId}/status`)
        .get();

      return ref.docs.map((doc) => ({
        id: doc.id,
        ...castTimestamp(doc),
      }));
    }
  }

  async changeStatus(applicationId: string, userId: string, productId: string, data: IStatusData, files?: Array<File>) {
    try {
      if (this.backend.vendorId && data && applicationId) {
        await this.backend.update(
          `${this.partnersPath}/${this.backend.vendorId}/${this.applicationPath}`,
          applicationId,
          {
            currentStatus: data.currentStatus,
            statusInfo: data.statusInfo,
            userLabel: data.userLabel
          },
        );

        if (files && files.length && productId === "motor") {
          files.forEach(async (file) => {
            await this.upload(applicationId, userId, file, "inspectionFiles");
          });
        }

        return;
      }
    } catch (error) {
      throw new Error();
    }
  }

  async download(filePath: string): Promise<string> {
    return await this.backend.downloadURL(`${this.bucketPath}/${filePath}`);
  }

  async upload(applicationId: string, userId: string, pdf: File, folderName?: string) {
    const filePath = folderName ? `${folderName}/${pdf.name}` : `${pdf.name}`;
    return await this.backend.storage
      .refFromURL(`${this.bucketPath}/${this.backend.vendorId}/applications/${userId}/${applicationId}/${filePath}`)
      .put(pdf);
  }

  getStatusLogs(applicationId: string) {
    if (this.backend.vendorId) {
      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.applicationPath}/${applicationId}/${this.statusLogPath}`,
          false,
          null,
          {
            field: "timestamp",
            direction: "asc",
          },
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newData = {
                id: change.doc.id,
                ...castTimestamp(change.doc),
              };
              this.logs.unshift(newData);
            }
            if (change.type === "removed") {
              this.logs = this.logs.filter((log) => {
                return log.id !== change.doc.id;
              });
            }
            if (change.type === "modified") {
              const objIndex = this.logs.findIndex((log) => log.id === change.doc.id);
              this.logs[objIndex] = { ...castTimestamp(change.doc) };
            }
          });
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }
}
