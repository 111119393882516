// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { Box } from "grommet";
import { Backend } from "../../../services/Backend";
import { IMatchProps } from "../../../interfaces/Props";
import { IStatusData } from "../../../interfaces/Logs";
import { NoDataFound } from "../../NoDataFound";
import { LogCard } from "../../LogCard";
import { Policy } from "../../../services/Policy";

export function PolicyLogs({ backend, match }: { backend: Backend; match: IMatchProps }) {
  const {
    params: { policyId },
  } = match;
  const [logs, setLogs] = useState<Array<IStatusData>>([]);
  const policyInstance = Policy.getInstance(backend);

  useEffect(() => {
    policyInstance.onSnapshot(() => setLogs([...policyInstance.logs]));
    policyInstance.getStatusLogs(policyId);
    // eslint-disable-next-line
  }, []);

  const renderData = () => {
    if (logs.length > 0) {
      return (
        <Box flex justify="around" direction="column" pad="small">
          {logs.map((log: IStatusData, index: number) => {
            return <LogCard key={index} log={log} />;
          })}
        </Box>
      );
    } else return <NoDataFound data={`No logs available for Policy: ${policyId}`} />;
  };

  return renderData();
}
