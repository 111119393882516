// eslint-disable-next-line
import React from "react";
import { Grommet, Box, Stack, Meter, Text } from "grommet";
import { bringy } from "../../styles";

export default function ProgressCircle({ meterValue }: { meterValue: number }) {
  return (
    <Grommet theme={bringy}>
      <Box align="center" pad="xsmall">
        <Stack anchor="center">
          <Meter type="circle" background="light-2" values={[{ value: meterValue }]} size="xsmall" thickness="small" />
          <Box direction="row" align="center" pad={{ bottom: "xsmall" }}>
            <Text size="small" weight="bold">
              {meterValue}
            </Text>
            <Text size="medium">%</Text>
          </Box>
        </Stack>
        <Text size="small">Uploading ....</Text>
      </Box>
    </Grommet>
  );
}
