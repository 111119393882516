// eslint-disable-next-line
import React from "react";
import { Box, Heading } from "grommet";

export function Home() {
  return (
    <Box flex direction="column" justify="center" align="center" pad="medium">
      <Heading level={3}>Welcome to bringy portal</Heading>
    </Box>
  );
}
