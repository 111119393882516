// eslint-disable-next-line
import React, { useState } from "react";
import { Box, Button, Heading } from "grommet";
import { IMotorApplicationData } from "../../../../interfaces/Application/motor";
import { Backend } from "../../../../services/Backend";
import { Application } from "../../../../services/Application";
import { ApplicationPageStatus } from "../../ApplicationPageStatus";
import { FileCard } from "../../../File";
import { Card } from "../../../Card";
import { history } from "../../../../_helpers";

export function MotorApplicationPage({
  application,
  applicationId,
  applicationInstance,
  backend,
}: {
  application: IMotorApplicationData;
  applicationId: string;
  applicationInstance: Application;
  backend: Backend;
}) {
  const [statusFlag, setStatusFlag] = useState<boolean>(false);
  const displayAttachments = (data: Array<string>, name: string, downloadFlag: boolean) => {
    if (data && data.length > 0) {
      return (
        <FileCard
          data={data}
          cardTitle={name}
          downloadingFlag={downloadFlag}
          downloadURL={(path: string) => applicationInstance.download(path)}
        />
      );
    }
  };

  const handlePDF = () => {
    if (application.attachments) {
      return (
        <Box flex direction="column" justify="start" align="start" gap="small">
          {displayAttachments(application.attachments.carInvoice, "Car Invoice", false)}
          {displayAttachments(application.attachments.carLicence, "Car Licence", false)}
          {displayAttachments(application.attachments.driverLicence, "Driver Licence", false)}
          {displayAttachments(application.attachments.nationalIdPhotos, "ID Photos", false)}
          {displayAttachments(application.attachments.carPhotosZip, "Car Photos", true)}
        </Box>
      );
    }
  };

  const showLogs = async () => {
    history.push(`/applications/${applicationId}/logs`);
  };

  const goToPolicy = async (policyId: string) => {
    history.push(`/policies/${policyId}`);
  };

  const changeStatusFlag = async (flag: boolean) => {
    setStatusFlag(flag);
  };

  const renderData = () => {
    return (
      <Box flex direction="column" pad="small" justify="start">
        <Box gap="small">
          <Card heading="Reference ID" body={`${application.partnerReferenceId}`} />
          <Card heading="Quotation ID" body={`${application.finalQuotationId || application.quotationId}`} />
          <Card heading="Created At" body={`${application.timestamp}`} />
          <Card
            heading="User Details"
            body={`${application.fullName}, Mobile: ${application.phoneNumber}, ID: ${application.nationalIdNumber}`}
          />
          <Card
            heading="Car Details"
            body={`${application.productData.brand} ${application.productData.model} ${application.productData.year} ${2.5/100}`}
          />

          {application.deliveryAddress ? (
            <Box flex direction="row" justify="start" align="center" gap="small">
              <Heading margin="none" level={4}>
                Delivery Address :
              </Heading>
              <Box
                flex="shrink"
                direction="column"
                pad="small"
                gap="xsmall"
                border={{ color: "brand", size: "xsmall" }}
              >
                <Card heading="Apartment" body={application.deliveryAddress.apt || "not provided"} />
                <Card heading="Floor" body={`${application.deliveryAddress.floor || "not provided"}`} />
                <Card heading="Building" body={application.deliveryAddress.building || "not provided"} />
                <Card heading="Street" body={application.deliveryAddress.street} />
                <Card heading="Area" body={`${application.deliveryAddress.area || "not provided"}`} />
                <Card
                  heading="City"
                  body={`${application.deliveryAddress.city} ${application.deliveryAddress.state} ${application.deliveryAddress.country}`}
                />
              </Box>
            </Box>
          ) : null}

          {application.beneficiary ? <Card heading="Beneficiary" body={application.beneficiary} /> : null}

          <Box flex direction="row" justify="start" align="center" gap="small">
            <Heading margin="none" level={4}>
              Quotation Details :
            </Heading>
            <Box flex="shrink" direction="column" pad="small" gap="xsmall" border={{ color: "brand", size: "xsmall" }}>
              <Card
                heading="Final Premium"
                body={`${application.finalQuotation || application.premium} ${application.currency}, ${
                  application.productData.quotationDetails.packageName
                }`}
              />
              <Card
                heading="Coverage"
                body={`${application.finalCoverage || application.coverage} ${application.currency}`}
              />

              {/* {Object.keys(
                application.productData.finalQuotationDetails?.breakdown ||
                  application.productData.quotationDetails.breakdown,
              ).map((item: string) => {
                return (
                  <Card
                    key={item}
                    heading={item}
                    body={`${application.productData.quotationDetails.breakdown[item]}`}
                  />
                );
              })} */}
            </Box>
          </Box>

          <Box flex direction="row" justify="start" align="center" gap="small">
            <Heading margin="none" level={4}>
              Status : {`${application.currentStatus}`}
            </Heading>
            <Button secondary label="View Logs" onClick={() => showLogs()} />
            <Button primary label="Change Status" onClick={() => changeStatusFlag(true)} />
            {application.policyId ? (
              <Button primary label="Policy Link" onClick={() => goToPolicy(application.policyId)} />
            ) : null}
          </Box>
          {statusFlag ? (
            <ApplicationPageStatus
              applicationInstance={applicationInstance}
              applicationId={application.id}
              userId={application.userId}
              productId={application.productId}
              status={application.currentStatus}
              uid={backend.auth.currentUser.uid}
              changeStatusFlag={(flag: boolean) => changeStatusFlag(flag)}
            />
          ) : null}
          {handlePDF()}
        </Box>
      </Box>
    );
  };

  return renderData();
}
