// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, DataTable } from "grommet";
import moment from "moment";

import { Backend } from "../../../services/Backend";
import { ApplicationsList } from "../../../services/Application";
import { DateFilter } from "../../DateFilter";
import { columns } from "./columns";
import { history } from "../../../_helpers";
import { ApplicationAction } from "../../../_actions";
import { AppState, IApplicationState } from "../../../interfaces/State";
import { ApplicationListStatus } from "../ApplicationListStatus";
import { IProductObject } from "../../../interfaces/Props";

export function ApplicationList({ backend }: { backend: Backend }) {
  const dispatch = useDispatch();
  const applicationActions = new ApplicationAction();

  const { applicationState }: { applicationState: IApplicationState } = useSelector((state: AppState) => ({
    applicationState: state.application,
  }));

  const [applications, setApplications] = useState<Array<any>>([]);
  const [filteringData, setFilteringData] = useState({
    product: applicationState.applicationFilteringData.product
      ? applicationState.applicationFilteringData.product
      : { label: "All Products", id: "All Products" },
    status: applicationState.applicationFilteringData.status ? applicationState.applicationFilteringData.status : "all",
  });
  const applicationInstance = ApplicationsList.getInstance(backend);

  const getCurrentDate = () => {
    return {
      end: moment().format("YYYY-MM-DD"),
      start: moment().subtract(6, "days").format("YYYY-MM-DD"),
    };
  };

  const [searchRange, setSearchRange] = useState({
    startDate: applicationState.applicationDateRange[0]
      ? applicationState.applicationDateRange[0]
      : getCurrentDate().start,
    endDate: applicationState.applicationDateRange[1] ? applicationState.applicationDateRange[1] : getCurrentDate().end,
  });

  const handleFormData = (applicationDateRange: Array<string>) => {
    dispatch(applicationActions.addDateRange(applicationDateRange));
    setSearchRange({ ...searchRange, startDate: applicationDateRange[0], endDate: applicationDateRange[1] });
  };

  const handleFilteredData = (product: IProductObject, status: string) => {
    dispatch(applicationActions.addFilteringData({ product, status }));
    setFilteringData({ product, status });
  };

  const displayApplicationDetails = (id: string) => {
    history.push(`/applications/${id}`);
  };

  useEffect(() => {
    applicationInstance.onSnapshot(() => setApplications([...applicationInstance.applications]));
    applicationInstance.get(searchRange.startDate, searchRange.endDate, filteringData.product.id, filteringData.status);
    // eslint-disable-next-line
  }, [searchRange, filteringData]);

  return (
    <Box flex justify="around" direction="column" pad="small">
      <DateFilter startDate={searchRange.startDate} endDate={searchRange.endDate} handleFormData={handleFormData} />
      <Box flex align="center" direction="column" pad="small">
        <ApplicationListStatus
          applicationInstance={applicationInstance}
          status={filteringData.status}
          product={filteringData.product}
          handleFilteredData={(product: IProductObject, status: string) => handleFilteredData(product, status)}
          applications={applications}
        />
      </Box>
      <DataTable
        columns={columns}
        data={applications}
        pin
        fill
        resizeable
        sortable
        border="bottom"
        sort={applicationState.sort}
        background={{
          header: "table-header",
        }}
        onSort={(sort) => dispatch(applicationActions.addSorting(sort))}
        onClickRow={(event) => displayApplicationDetails(event.datum.id)}
      />
    </Box>
  );
}
