import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { alert } from "./alert.reducer";
import { loading } from "./loading.reducer";
import { policy } from "./policy.reducer";
import { application } from "./application.reducer";

import { AppState } from "../interfaces/State";

const rootReducer = combineReducers<AppState>({
  authentication,
  alert,
  loading,
  policy,
  application,
});

export default rootReducer;
