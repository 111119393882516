import React, { useState } from "react";
import { IStatusData } from "../../../interfaces/Logs";
import { IPolicyPageStatusProps } from "../../../interfaces/Props";
import { Alert } from "../../../_actions";
import { history } from "../../../_helpers";
import { PolicyStatusForm } from "../../Status";

export function PolicyPageStatus({ policyInstance, policyId, status, uid }: IPolicyPageStatusProps) {
  const alertAction = new Alert();

  const [statusData, setStatusData] = useState<IStatusData>({
    previousStatus: status,
    currentStatus: null,
    timestamp: null,
    statusInfo: { notes: "" },
    uid,
  });

  const submitStatus = () => {
    if (statusData.currentStatus && statusData.currentStatus !== statusData.previousStatus) {
      policyInstance.changeStatus(policyId, statusData);
      alertAction.pass("Policy status is changed successfuly");
    } else {
      alertAction.fail("Please select a status");
    }
  };

  const handleStatusChange = (option: string) => {
    setStatusData({ ...statusData, currentStatus: option, timestamp: new Date() });
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusData({ ...statusData, statusInfo: { notes: event.target.value } });
  };

  const showLogs = () => {
    history.push(`/policies/${policyId}/logs`);
  };

  return (
    <PolicyStatusForm
      defaultValue={statusData.currentStatus || statusData.previousStatus}
      statusOptions={["issued", "canceled"]}
      notes={statusData.statusInfo.notes}
      handleStatusChange={handleStatusChange}
      handleNotesChange={handleNotesChange}
      submitStatus={submitStatus}
      showLogs={showLogs}
    />
  );
}
