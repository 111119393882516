import { Form, Box, FormField, DateInput, Button } from "grommet";
import React from "react";
import { IDateSearchProps } from "../../interfaces/Props";

export function DateFilter({ startDate, endDate, handleFormData }: IDateSearchProps) {
  return (
    <Form
      onSubmit={({ value }: any) => {
        handleFormData(value.dateRange ? value.dateRange : [startDate, endDate]);
      }}
    >
      <Box flex direction="row" align="center" gap="small">
        <FormField>
          <DateInput
            id="dateRange"
            format="dd/mm/yyyy to dd/mm/yyyy"
            defaultValue={[startDate, endDate]}
            name="dateRange"
          />
        </FormField>
        <Button type="submit" primary label="Filter" />
      </Box>
    </Form>
  );
}
