/* eslint-disable react/prop-types */
// eslint-disable-next-line
import React from "react";
import { Grid, Box, Grommet } from "grommet";

import { NavBar } from "../NavBar";
import { SideBar } from "../SideBar";
import { Content } from "../Content";
import { bringy } from "../../styles";
import { Backend } from "../../services/Backend";
import { ErrorBoundary } from "../ErrorBoundary";

export function Sitewrapper({ backend, children }: { backend: Backend; children: any }) {
  return (
    <Grommet theme={bringy} full={true}>
      <Box align="stretch" justify="start" fill>
        <NavBar backend={backend} />
        <Box align="center" justify="start" direction="row" fill>
          <Grid columns={["200px", "auto"]} fill>
            <SideBar />
            <ErrorBoundary>
              <Content>{children}</Content>
            </ErrorBoundary>
          </Grid>
        </Box>
      </Box>
    </Grommet>
  );
}
