// eslint-disable-next-line
import React from "react";
import { IErrorBoundaryState } from "../../interfaces/State";
import { IErrorProps } from "../../interfaces/Props";
import { Box, Heading } from "grommet";

export class ErrorBoundary extends React.Component<IErrorProps, IErrorBoundaryState> {
  constructor(props: IErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      <Box flex direction="column" justify="center" align="center" pad="medium">
        <Heading level={4}>Something Went Wrong!!</Heading>
      </Box>
    ) : (
      this.props.children
    );
  }
}
