import React from "react";
import { Form, Box, FormField, Button, Select } from "grommet";
import { ISelectProps } from "../../interfaces/Props";

export function SelectFilter({
  defaultValue,
  options,
  handleValueSelection,
  buttonName,
  hasButton = false,
  placeholder,
}: ISelectProps) {
  return (
    <Form>
      <Box flex direction="row" align="center" gap="small">
        <FormField>
          <Select
            placeholder={placeholder}
            value={defaultValue}
            options={options}
            onChange={({ option }) => handleValueSelection(option)}
          />
        </FormField>
        {hasButton ? <Button type="submit" primary label={buttonName} /> : null}
      </Box>
    </Form>
  );
}
