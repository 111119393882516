import { policyConstants } from "../_constants";
import { IPolicyAction } from "../interfaces/Actions";
import { IPolicyState } from "../interfaces/State";

const initialPolicyState: IPolicyState = {
  policyDateRange: [],
  sort: {
    property: "timestamp",
    direction: "asc",
  },
};

export function policy(state: IPolicyState = initialPolicyState, action: IPolicyAction) {
  switch (action.type) {
    case policyConstants.ADD_POLICY_DATE_RANGE:
      return {
        ...state,
        policyDateRange: action.policyDateRange,
      };
    case policyConstants.ADD_POLICY_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case policyConstants.RESET_POLICY_STATE:
      return {
        ...state,
        policyDateRange: [],
        sort: {
          property: "timestamp",
          direction: "asc",
        },
      };
    default:
      return state;
  }
}
