import React from "react";
import { Box } from "grommet";
import { IStatusData } from "../../interfaces/Logs";

interface ILogsProps {
  log: IStatusData;
}

export function LogCard({ log }: ILogsProps) {
  return (
    <Box border pad="small">
      <Box background={{ color: "table-header" }} margin={{ bottom: "small" }}>
        {log.timestamp}
      </Box>
      <Box>{`Current Status: ${log.currentStatus}`}</Box>
      <Box>{`Previous Status: ${log.previousStatus}`}</Box>
      <Box>{`Updated By: ${log.uid ? log.uid : "not provided"}`}</Box>
      <Box>{`Data: ${
        log.statusInfo && Object.keys(log.statusInfo).length ? JSON.stringify(log.statusInfo) : "no data are given"
      }`}</Box>
    </Box>
  );
}
