import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationStatusData } from "../../../interfaces/Application";
import { IStatusData } from "../../../interfaces/Logs";
import { IApplicationPageStatusProps } from "../../../interfaces/Props";
import { IApplicationState, AppState } from "../../../interfaces/State";
import { Alert, ApplicationAction } from "../../../_actions";
import { ApplicationStatusForm } from "../../Status/Application";

export function ApplicationPageStatus({
  applicationInstance,
  applicationId,
  userId,
  productId,
  status,
  uid,
  changeStatusFlag,
}: IApplicationPageStatusProps) {
  const alertAction = new Alert();
  const dispatch = useDispatch();
  const applicationActions = new ApplicationAction();

  const { applicationState }: { applicationState: IApplicationState } = useSelector((state: AppState) => ({
    applicationState: state.application,
  }));

  const [statusData, setStatusData] = useState<IStatusData>({
    userLabel: null,
    previousStatus: status,
    currentStatus: null,
    statusInfo: null,
    timestamp: null,
    uid,
  });

  const submitStatus = async (data: any, statusObj: IApplicationStatusData, files: Array<File>) => {
    try {
      if (statusData.currentStatus && statusData.currentStatus !== statusData.previousStatus) {
        statusData.timestamp = new Date();
        const statusInfo: IApplicationStatusData = {
          id: statusObj.id,
          label: statusObj.label,
          data: {
            form: { ...data },
          },
          sla: statusObj.sla,
          updatedAt: statusData.timestamp,
          responseBefore: new Date(moment().add(statusObj.sla, "hours").format()),
          requiresAction: statusObj.requiresAction || false,
          informUser: statusObj.informUser || false,
          informPartner: statusObj.informPartner || false,
          updatedBy: statusData.uid,
          hideFromUser: statusObj.hideFromUser || false,
          userLabel: statusData.userLabel
        };
        await applicationInstance.changeStatus(applicationId, userId, productId, { ...statusData, statusInfo }, files);
        alertAction.pass("Application status is changed successfuly");
        changeStatusFlag(false);
      } else {
        alertAction.fail("Please select a status");
      }
    } catch (error) {
      alertAction.fail("Something went wrong. Please try later");
    }
  };

  function getStatusOptions() : Array<IApplicationStatusData> {
    const restrictedList = applicationState.status[productId].find(
      (item: IApplicationStatusData) => item.label === statusData.previousStatus,
    ).restrictedList || [];
    
    const statusOptions = applicationState.status[productId].filter((item: IApplicationStatusData) => {
      return [...restrictedList].includes(item.label);
    });
    
    return statusOptions;
  }

  const handleStatusChange = (option: string) => {
    const statusOptions = getStatusOptions();
    let userLabel = null;
    statusOptions.forEach(element => {
      if (element.label == option) {
        userLabel = element.userLabel ? element.userLabel : null;
      }
    });
    setStatusData({ ...statusData, currentStatus: option, userLabel: userLabel });
  };

  const renderData = () => {
    if (applicationState.status && applicationState.status[productId]) {

      return (
        <ApplicationStatusForm
          defaultValue={statusData.currentStatus || statusData.previousStatus}
          statusOptions={getStatusOptions()}
          handleStatusChange={handleStatusChange}
          submitStatus={(formData: any, statusData: IApplicationStatusData, files: Array<File>) =>
            submitStatus(formData, statusData, files)
          }
          changeStatusFlag={(flag: boolean) => changeStatusFlag(flag)}
        />
      );
    } else {
      dispatch(applicationActions.fetchProductStatusList(productId, applicationInstance));
      return null;
    }
  };

  return renderData();
}
