// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, DataTable } from "grommet";
import { Checkmark } from "grommet-icons";
import moment from "moment";

import { Backend } from "../../../services/Backend";
import { PoliciesList } from "../../../services/Policy";
import { NoDataFound } from "../../NoDataFound";
import { DateFilter } from "../../DateFilter";
import { history } from "../../../_helpers";
import { PolicyAction } from "../../../_actions";
import { AppState, IPolicyState } from "../../../interfaces/State";

const columns = [
  {
    search: true,
    sortable: true,
    primary: true,
    property: "serialNumber",
    header: "Serial Number",
  },
  {
    search: true,
    sortable: true,
    property: "title",
    header: "Title",
  },
  {
    search: true,
    sortable: true,
    property: "fullName",
    header: "Policy holder name",
  },
  {
    search: true,
    sortable: true,
    property: "email",
    header: "Policy holder email",
  },
  {
    search: true,
    sortable: true,
    property: "nationalId",
    header: "National ID",
  },
  {
    search: true,
    sortable: false,
    property: "premiumWithCurrency",
    header: "Premium",
  },
  {
    sortable: true,
    property: "timestamp",
    header: "Creation Time",
  },
  {
    property: "policyPath",
    sortable: false,
    header: "PDF",
    // eslint-disable-next-line react/display-name
    render: (datum: any) => (datum.policyPath ? <Checkmark /> : null),
  },
];

export function PolicyList({ backend }: { backend: Backend }) {
  const dispatch = useDispatch();
  const policyActions = new PolicyAction();

  const { policyState }: { policyState: IPolicyState } = useSelector((state: AppState) => ({
    policyState: state.policy,
  }));

  const [policies, setPolicies] = useState<Array<any>>([]);
  const policyInstance = PoliciesList.getInstance(backend);

  const getCurrentDate = () => {
    return {
      end: moment().format("YYYY-MM-DD"),
      start: moment().subtract(6, "days").format("YYYY-MM-DD"),
    };
  };

  const [searchRange, setSearchRange] = useState({
    startDate: policyState.policyDateRange[0] ? policyState.policyDateRange[0] : getCurrentDate().start,
    endDate: policyState.policyDateRange[1] ? policyState.policyDateRange[1] : getCurrentDate().end,
  });

  const handleFormData = (policyDateRange: Array<string>) => {
    dispatch(policyActions.addPolicyDateRange(policyDateRange));
    setSearchRange({ ...searchRange, startDate: policyDateRange[0], endDate: policyDateRange[1] });
  };

  const displayPolicyDetails = (policyId: string) => {
    history.push(`/policies/${policyId}`);
  };

  useEffect(() => {
    policyInstance.onSnapshot(() => setPolicies([...policyInstance.policies]));
    policyInstance.get(searchRange.startDate, searchRange.endDate);
    // eslint-disable-next-line
  }, [searchRange]);

  return (
    <Box flex justify="around" direction="column" pad="small">
      <DateFilter startDate={searchRange.startDate} endDate={searchRange.endDate} handleFormData={handleFormData} />
      {policies.length > 0 ? (
        <DataTable
          columns={columns}
          data={policies}
          pin
          fill
          resizeable
          sortable
          border="bottom"
          sort={policyState.sort}
          background={{
            header: "light-1",
          }}
          onSort={(sort) => dispatch(policyActions.addSorting(sort))}
          onClickRow={(event) => displayPolicyDetails(event.datum.id)}
        />
      ) : (
        <NoDataFound data="No Policies are found for this date range" />
      )}
    </Box>
  );
}
