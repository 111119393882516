import { ISortBy } from "../interfaces/Actions";
import { policyConstants } from "../_constants";

export class PolicyAction {
  public addPolicyDateRange = (policyDateRange: Array<string>) => {
    return {
      type: policyConstants.ADD_POLICY_DATE_RANGE,
      policyDateRange,
    };
  };

  public addSorting = (sort: ISortBy) => {
    return {
      type: policyConstants.ADD_POLICY_SORT,
      sort,
    };
  };

  public resetPolicyState = () => {
    return {
      type: policyConstants.RESET_POLICY_STATE,
    };
  };
}
