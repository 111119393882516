// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grommet, Heading, Text } from "grommet";
import { Download, Upload, Trash } from "grommet-icons";

import { IPolicyData } from "../../../interfaces/Policy";
import { IMatchProps } from "../../../interfaces/Props";
import { Backend } from "../../../services/Backend";
import { Policy } from "../../../services/Policy";
import { Alert } from "../../../_actions";
import { bringy } from "../../../styles";
import { NoDataFound } from "../../NoDataFound";
import ProgressCircle from "../../ProgressCircle";
import { FileItem } from "../../File";
import { Card } from "../../Card";
import { PolicyPageStatus } from "../PolicyPageStatus";
import { IStatusData } from "../../../interfaces/Logs";
import { history } from "../../../_helpers";
import { Loading } from "../../Loading";

export function PolicyPage({ backend, match }: { backend: Backend; match: IMatchProps }) {
  const policyInstance = Policy.getInstance(backend);
  const dispatch = useDispatch();
  const alert = new Alert();

  const {
    params: { policyId },
  } = match;

  const [policy, setPolicy] = useState<IPolicyData>(null);
  const [selectedFile, setFile] = useState<any>(null);
  const [uploadingProgress, setUploadingProgress] = useState<number>(
    policyInstance.progress[policyId] ? policyInstance.progress[policyId] : 0,
  );

  useEffect(() => {
    policyInstance.onSnapshot(() => setPolicy(policyInstance.policy));
    policyInstance.get(policyId);
    // eslint-disable-next-line
  }, []);

  const chooseAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.files![0] as File;
    setFile(input);
  };

  const executeUploading = () => {
    setUploadingProgress(policyInstance.progress[policy.id]);
    setPolicy(policyInstance.policy);
  };

  const uploadFile = () => {
    try {
      if (selectedFile) {
        policyInstance.onSnapshot(() => executeUploading());
        policyInstance.upload(policy.id, policy.userId, policy.serialNumber, selectedFile);
        const logs: IStatusData = {
          currentStatus: policy.status ? policy.status : "issued",
          previousStatus: "",
          statusInfo: { notes: "New file has been uploaded" },
          uid: backend.auth.currentUser.uid,
          timestamp: new Date(),
        };
        policyInstance.setLog(policy.id, logs);
        dispatch(alert.pass("Sucessfully upload a policy file"));
        setFile(null);
      } else {
        dispatch(alert.fail("Please select a file first"));
      }
    } catch (error) {
      dispatch(alert.fail("Failed to upload policy pdf"));
    }
  };

  const downloadPolicy = async () => {
    try {
      const url = await policyInstance.downloadFile(policy.policyPath);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = `${policy.serialNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(alert.fail("Failed to download. Please try later"));
    }
  };

  const removePdf = async () => {
    try {
      await policyInstance.removePdf(policy.id);
      dispatch(alert.pass("Sucessfully remove a policy file"));
      setUploadingProgress(0);
      return;
    } catch (error) {
      dispatch(alert.fail("Failed to remove pdf for this user. Please try later"));
    }
  };

  const displayAttachments = (filePath: string, name: string, downloadingFlag: boolean) => {
    return (
      <FileItem
        fileName={name}
        filePath={filePath}
        downloadingFlag={downloadingFlag}
        downloadURL={(path: string) => policyInstance.downloadFile(path)}
      />
    );
  };

  const displayIdImages = () => {
    if (policy.otherIdFront && policy.otherIdBack) {
      return (
        <Box flex direction="row" justify="start" align="start" gap="small">
          {displayAttachments(policy.otherIdFront, "ID Front Image", false)}
          {displayAttachments(policy.otherIdBack, "ID Back Image", false)}
        </Box>
      );
    }
  };

  const handlePDF = () => {
    if (policy.policyPath) {
      return (
        <Box flex direction="row" justify="start" align="center" gap="small">
          <Heading margin="none" level={4}>
            PDF :
          </Heading>
          <Button icon={<Download />} hoverIndicator label="View Policy" onClick={() => downloadPolicy()} />
          <Button icon={<Trash />} hoverIndicator label="Remove Policy" onClick={() => removePdf()} />
        </Box>
      );
    } else if (uploadingProgress === 100) {
      return <Loading />;
    } else if (uploadingProgress > 0 && uploadingProgress < 100) {
      return <ProgressCircle meterValue={uploadingProgress} />;
    } else {
      return (
        <Box
          flex
          direction="row"
          width="medium"
          gap="small"
          align="center"
          border={{ color: "brand", size: "small" }}
          pad="medium"
        >
          <Box flex direction="column" align="start">
            <label
              style={{
                border: "1px solid #ccc",
                borderColor: "brand",
                display: "inline-block",
                padding: "12px",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                id="fileUploading"
                accept=".pdf"
                onChange={(event) => chooseAttachment(event)}
              />
              Choose File
            </label>
            <Box>{selectedFile ? selectedFile.name : null}</Box>
          </Box>
          <Button icon={<Upload />} hoverIndicator label="Upload" onClick={() => uploadFile()} />
        </Box>
      );
    }
  };

  const showLogs = () => {
    history.push(`/policies/${policy.id}/logs`);
  };

  const renderData = () => {
    if (policy && policy.exists) {
      return (
        <Grommet theme={bringy} full={true}>
          <Box flex direction="column" pad="small" justify="start" alignContent="start" gap="small">
            <Card heading="Policy Type" body={`${policy.productId}`} />
            <Card heading="Policy Serial Number" body={`${policy.serialNumber}, created at ${policy.timestamp}`} />
            <Card heading="Policy Title" body={`${policy.title}`} />
            {policy.description ? <Card heading="Policy Description" body={`${policy.description}`} /> : null}
            <Card heading="Policy Duration" body={`From: ${policy.startDate} To: ${policy.endDate}`} />
            <Card
              heading="User Details"
              body={`${policy.fullName}, Mobile: ${policy.phoneNumber ? policy.phoneNumber : "not provided"}, ID: ${
                policy.nationalId ? policy.nationalId : "not provided"
              }
              , Email: ${policy.email}`}
            />
            <Card heading="Premium" body={`${policy.premiumWithCurrency}`} />
            {policy.status !== "canceled" ? (
              <PolicyPageStatus
                policyInstance={policyInstance}
                policyId={policy.id}
                status={policy.status ? policy.status : "issued"}
                uid={backend.auth.currentUser.uid}
              />
            ) : (
              <Box flex direction="row" align="center" gap="small">
                <Heading margin="none" level={4}>
                  Status :
                </Heading>
                <Text>{policy.status}</Text>
                <Button secondary label="View Logs" onClick={() => showLogs()} />
              </Box>
            )}
            {policy.status !== "canceled" ? displayIdImages() : null}
            {policy.status !== "canceled" ? handlePDF() : null}
          </Box>
        </Grommet>
      );
    } else return <NoDataFound data="No data available for this policy" />;
  };

  return renderData();
}
