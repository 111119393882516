// eslint-disable-next-line
import React from "react";
import { Nav, Button} from "grommet";
import { Apps, Gift, Plan } from "grommet-icons";
import { history } from "../../../_helpers";

export function SideBarItems() {
  const showPolicies = () => {
    history.push("/policies");
  };

  const showApplications = () => {
    history.push("/applications");
  };

  const showAddVoucherToUser = () => {
    history.push("/addVoucherToUser");
  };

  const showAddedVouchersReport = () => {
    history.push("/addedVouchersReport");
  };

  return (
    <Nav gap="medium" responsive={false} flex direction="column" align="start">
      <Button icon={<Apps />} label="Policies" plain onClick={() => showPolicies()} />
      <Button icon={<Plan />} label="Applications" plain onClick={() => showApplications()} />
      <Button icon={<Gift />} label="Add voucher to user" plain onClick={() => showAddVoucherToUser()} />
      <Button icon={<Gift />} label="Added vouchers report" plain onClick={() => showAddedVouchersReport()} />
    </Nav>
  );
}
