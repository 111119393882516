// eslint-disable-next-line
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormField, TextInput, Box, Button, Grommet } from "grommet";

import { Alert, Authentication } from "../../_actions";
import { Backend } from "../../services/Backend";
import { bringy } from "../../styles";
import { LogoImage } from "../Logo/Image";

export function Login({ backend, location }: { backend: Backend; location: any }) {
  const dispatch = useDispatch();
  const authentication = new Authentication();
  const alert = new Alert();

  const [credentials, setCredentials] = useState({
    email: null,
    password: null,
  });

  const handleLogin = () => {
    if (credentials.email && credentials.password) {
      dispatch(authentication.toLogin(credentials.email, credentials.password, backend, location));
    } else {
      dispatch(alert.fail("Please fill the form"));
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, email: event.target.value });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, password: event.target.value });
  };

  return (
    <Grommet full theme={bringy}>
      <Box fill align="center" justify="center">
        <Box width="medium">
          <LogoImage />
          <Form
            onReset={() => setCredentials({ ...credentials, email: null, password: null })}
            onSubmit={() => handleLogin()}
          >
            <FormField
              value={credentials.email}
              htmlFor="email"
              label="Email"
              onChange={(event) => handleEmailChange(event)}
            >
              <TextInput id="email" placeholder="Enter Your Email" />
            </FormField>
            <FormField
              value={credentials.password}
              htmlFor="password"
              label="Password"
              onChange={(event) => handlePasswordChange(event)}
            >
              <TextInput type="password" id="password" placeholder="Enter Your Password" />
            </FormField>
            <Box direction="row" justify="center">
              <Button type="submit" primary label="Sign in" />
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  );
}
