import React from "react";
import { Anchor, Box, Heading, Text } from "grommet";
import { ICardProps } from "../../interfaces/Props";

export function Card({ heading, body, link }: ICardProps) {
  return (
    <Box flex direction="row" justify="start" align="center" gap="small">
      <Heading margin="none" level={4}>
        {heading} :
      </Heading>
      <Text>{body}</Text>
      {link ? <Anchor label={link.label} target="_blank" href={link.ref} /> : null}
    </Box>
  );
}
