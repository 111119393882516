import React, { useEffect, useState } from "react";
import { Box, DataTable, Heading } from "grommet";
import { columns } from "./columns";
import { VouchersService } from "../../../services/Vouchers/vouchersService";

import { Backend } from "../../../services/Backend";
import { DateFilter } from "../../DateFilter";
import moment from "moment";

export function AddedVouchersReport(props:any) {

  const backend:Backend = props.backend;
  const [voucherLogList, setVoucherLogList] = useState([]);
  
  const vouchersService = new VouchersService(backend);
  vouchersService.onListVouchersLogsChange((updatedVouchersLogsList:[])=>{
    setVoucherLogList(updatedVouchersLogsList);
  });  
  

  //FIXME: DateFilter has issue so i had to switch firstDate and endDate to be fixed later with DateFilter
  const [searchRange, setSearchRange] = useState({
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
  });

  useEffect(()=>{
    getVouchersLogs(searchRange.startDate, searchRange.endDate);
  }, [searchRange]);

  const handleDateRangeChanged = (dateRange:Array<string>) => {
    setSearchRange({ ...searchRange, startDate: dateRange[0], endDate: dateRange[1] });
  };

  async function getVouchersLogs(fromDate:string, toDate:string) {
    vouchersService.getVouchersLogs(fromDate, toDate);
  }

  return (
    <Box flex justify="around" direction="column" pad="small">
      <Heading level={3}>Added vouchers report</Heading>
      <DateFilter startDate={searchRange.startDate} endDate={searchRange.endDate} handleFormData={handleDateRangeChanged} />
      <DataTable
        columns={[...columns, {
          search: false,
          sortable: false,
          property: "redeems",
          header: "Uses",
          // eslint-disable-next-line react/display-name
          render: datum => (
            <Box>
              {datum.redeems.map((aRedeem:any)=>{
                return (<span key={aRedeem.voucherRedeemedTime}>{aRedeem.voucherRedeemedTime}</span>);
              })}
            </Box>
          )
        }]}
        data={voucherLogList}
        pin
        fill
        resizeable
        sortable
        border="bottom"
        background={{
          header: "table-header",
        }}
      /> 
    </Box>
  );
}