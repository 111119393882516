import { Backend, castPolicyData } from "../Backend";
import { PartnersEnum } from "./../enums";

export class PoliciesList {
  private constructor(backend: Backend) {
    this.backend = backend;
  }

  public static getInstance(backend: Backend): PoliciesList {
    PoliciesList.instance = new PoliciesList(backend);
    return PoliciesList.instance;
  }

  public policies: Array<any> = [];
  private static instance: PoliciesList;
  private backend: Backend;
  private insurancePath: string = PartnersEnum.INSURANCE_PATH;
  private partnersPath: string = PartnersEnum.PARTNER_PATH;
  private onSnapshotCallback!: Function;

  onSnapshot(callback: Function) {
    this.onSnapshotCallback = callback;
  }

  get(startDate: string | null, endDate: string | null) {
    if (this.backend.vendorId) {
      const startAt = startDate ? new Date(startDate) : startDate;
      const endAt = endDate ? new Date(new Date(endDate).getTime() + 60 * 60 * 24 * 1000) : endDate;

      this.backend
        .whereEqualCompoundQuery(
          `${this.partnersPath}/${this.backend.vendorId}/${this.insurancePath}`,
          false,
          null,
          {
            field: "timestamp",
            direction: "asc",
          },
          startAt,
          endAt,
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newData = {
                id: change.doc.id,
                ...castPolicyData(change.doc),
              };
              this.policies.push(newData);
            }
            if (change.type === "removed") {
              this.policies = this.policies.filter((policy) => {
                return policy.id !== change.doc.id;
              });
            }
            if (change.type === "modified") {
              const objIndex = this.policies.findIndex((policy) => policy.id === change.doc.id);
              this.policies[objIndex] = { ...castPolicyData(change.doc) };
            }
          });
          if (this.onSnapshotCallback) this.onSnapshotCallback();
        });
    }
  }
}
