// eslint-disable-next-line
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { App } from "./App";
import { store } from "./_helpers/store";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import * as serviceWorker from "./serviceWorker";
import { FirebaseContext, Backend } from "./services/Backend";

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Backend()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
