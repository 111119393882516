export const columns = [
  {
    search: true,
    sortable: true,
    property: "partnerReferenceId",
    header: "Reference Id",
  },
  {
    search: true,
    sortable: true,
    primary: true,
    property: "quotationId",
    header: "Quotation Id",
  },
  {
    search: true,
    sortable: true,
    property: "currentStatus",
    header: "Status",
  },
  {
    search: true,
    sortable: false,
    property: "premiumWithCurrency",
    header: "Premium",
  },
  {
    search: true,
    sortable: true,
    property: "fullName",
    header: "User Name",
  },
  {
    search: true,
    sortable: true,
    property: "phoneNumber",
    header: "User Phone",
  },
  {
    search: true,
    sortable: true,
    property: "nationalIdNumber",
    header: "National ID",
  },
  {
    sortable: true,
    property: "timestamp",
    header: "Creation Time",
  },
];
