import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import lodash from "lodash";
import rootReducer from "../_reducers";
import { loadState, saveState } from "../sessionStorage";

const loggerMiddleware: any = createLogger();
const persistedState: any = loadState();

const middleware = [thunkMiddleware];
const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === "development") {
  middleware.push(loggerMiddleware);
}

export const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

store.subscribe(
  lodash.throttle(() => {
    saveState({
      authentication: store.getState().authentication,
      policy: store.getState().policy,
      application: store.getState().application,
    });
  }, 1000),
);
