// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Backend } from "../../services/Backend";
import { Loading } from "../Loading";

export const AuthContext = React.createContext<any | null>(null);

export const AuthProvider = ({ children, backend }: { children: any; backend: Backend }) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    backend.auth.onAuthStateChanged(async (user: any) => {
      setCurrentUser(user);
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        if (idTokenResult.claims.vendor) {
          backend.vendorId = idTokenResult.claims.vendor;
        }
      }
      setPending(false);
    });
    // eslint-disable-next-line
  }, []);

  if (pending) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
