// eslint-disable-next-line
import React from "react";
import { Box, Grommet, Sidebar } from "grommet";
import { SideBarItems } from "./SideBarItems";
import { bringy } from "../../styles";

export function SideBar() {
  return (
    <Grommet theme={bringy}>
      <Box direction="row" height={{ min: "100%" }}>
        <Sidebar flex direction="column" align="center" background={{ color: "dark-1" }}>
          <SideBarItems />
        </Sidebar>
      </Box>
    </Grommet>
  );
}
