// eslint-disable-next-line
import React from "react";
import { Box } from "grommet";

export function Loading() {
  return (
    <Box flex direction="row" justify="center">
      Loading .........
    </Box>
  );
}
