import firebase from "firebase/app";
import moment from "moment";

export const castPolicyData = (ref: firebase.firestore.DocumentSnapshot) => {
  try {
    if (!ref.exists) return {};
    const data = dataRecursion(ref.data()!);
    data.timestamp = moment(data.timestamp).format("DD/MM/YYYY, h:mm:ss a");
    data.startDate = moment(data.startDate).format("DD/MM/YYYY, h:mm:ss a");
    data.endDate = moment(data.endDate).format("DD/MM/YYYY, h:mm:ss a");
    data.premiumWithCurrency = `${data.quotation} ${data.currency}`;
    data.nationalId = data.nationalId ? data.nationalId : "not provided";
    return data;
  } catch (error) {
    throw new Error(`castPolicyData function failed for ID: ${ref.id}..`);
  }
};

export const castApplicationData = (ref: firebase.firestore.DocumentSnapshot) => {
  try {
    if (!ref.exists) return {};
    const data = dataRecursion(ref.data()!);
    data.timestamp = moment(data.timestamp).format("DD/MM/YYYY, h:mm:ss a");
    data.premiumWithCurrency = `${data.finalQuotation || data.premium} ${data.currency}`;
    data.nationalIdNumber = data.nationalIdNumber ? data.nationalIdNumber : "not provided";
    return data;
  } catch (error) {
    throw new Error(`castApplicationData function failed for ID: ${ref.id}..`);
  }
};

export const castVoucherLogData = (ref: firebase.firestore.DocumentSnapshot) => {
  try {
    if (!ref.exists) return {};
    const data = dataRecursion(ref.data()!);
    
    if (!data.mobileNumber) data.mobileNumber = "";
    data.voucherAdditionTime = moment(data.timestamp).format("DD/MM/YYYY, h:mm:ss a");

    if (!data.redeems) data.redeems = [];
    
    data.redeems.forEach((redeemElement:any) => {
      redeemElement.voucherRedeemedTime = moment(redeemElement.voucherRedeemedTime).format("DD/MM/YYYY, h:mm:ss a");
    });
    
    return data;
  } catch (error) {
    throw new Error(`castApplicationData function failed for ID: ${ref.id}..`);
  }
};

export function castTimestamp(ref: firebase.firestore.DocumentSnapshot) {
  try {
    if (!ref.exists) return {};
    const data = dataRecursion(ref.data());
    return data;
  } catch (error) {
    throw new Error(`castTimestamp function failed for ID: ${ref.id}..`);
  }
}

const dataRecursion = (data: firebase.firestore.DocumentData) => {
  Object.keys(data).forEach((key) => {
    if (!(data[key] === null || data[key] === undefined)) {
      if (data[key] instanceof firebase.firestore.Timestamp) data[key] = data[key].toDate().toGMTString();
      if (typeof data[key] === "object") dataRecursion(data[key]);
    }
  });
  return data;
};
