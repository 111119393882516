import React, { useState } from "react";
import { Form, Box, FormField, Select, Button } from "grommet";
import { IApplicationStatusData } from "../../../interfaces/Application";
import { CheckBoxStatus, TextStatus, SelectStatus, AttachFileStatus, DateStatus } from "../Application/Types";
interface IStatusProps {
  defaultValue: string;
  statusOptions: Array<IApplicationStatusData>;
  handleStatusChange(value: string): any;
  submitStatus(formData: any, statusData: IApplicationStatusData, files: Array<File>): any;
  changeStatusFlag(flag: boolean): any;
}

export function ApplicationStatusForm({
  defaultValue,
  statusOptions,
  submitStatus,
  handleStatusChange,
  changeStatusFlag,
}: IStatusProps) {
  const statusLabels = statusOptions.map((item) => item.label);
  const statusData = statusOptions.find((item) => item.label === defaultValue);
  const [selectedFiles, setFiles] = useState<Array<any>>([]);

  const checkStatusFormType = (
    type: string,
    label: string,
    required: boolean,
    key: string,
    name: string,
    options: Array<{
      label: string;
      id: string;
    }>,
  ) => {
    switch (type) {
      case "checkbox":
        return <CheckBoxStatus key={key} id={key} label={label} required={required} name={name} options={options} />;
      case "select":
        return <SelectStatus key={key} id={key} label={label} required={required} name={name} options={options} />;
      case "file":
        return (
          <AttachFileStatus
            key={key}
            id={key}
            label={label}
            required={required}
            name={name}
            selectedFiles={selectedFiles}
            chooseAttachment={chooseAttachment}
            removeAttachment={removeAttachment}
          />
        );
      case "text":
        return <TextStatus key={key} id={key} label={label} required={required} name={name} type={type} />;
      case "date":
        return <DateStatus key={key} id={key} label={label} name={name} />;
      default:
        return <TextStatus key={key} id={key} label={label} required={required} name={name} type={type} />;
    }
  };

  const chooseAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setFiles((prevState: any) => [...prevState, ...event.target.files]);
  };

  const removeAttachment = (name: string) => {
    setFiles((oldFiles) => [...oldFiles.filter((file: File) => file.name !== name)]);
  };

  return (
    <Box fill align="start" alignContent="center">
      <Box width="large" border margin="large" pad="large">
        <Form
          onSubmit={({ value }: any) => {
            for (const property in value) {
              if (
                typeof value[property] === "string" &&
                statusData.data.form[property] &&
                statusData.data.form[property].options
              )
                value[property] = statusData.data.form[property].options
                  .filter((option) => value[property].includes(option.label))
                  .map((item) => item.id);
              else if (typeof value[property] === "string") value[property] = [value[property]];
              else if (statusData.data.form[property].options)
                value[property] = statusData.data.form[property].options
                  .filter((option) => value[property].includes(option.label))
                  .map((item) => item.id);
            }
            submitStatus(value, statusData, selectedFiles);
          }}
        >
          <FormField name="name" htmlFor="currentStatus" label="Select Status">
            <Select
              value={defaultValue}
              id="currentStatus"
              options={statusLabels}
              onChange={({ option }) => handleStatusChange(option)}
            />
          </FormField>

          {statusData && statusData.data && statusData.data.form
            ? Object.keys(statusData.data.form).map((item: any, key: number) => {
                return checkStatusFormType(
                  statusData.data.form[item].type,
                  statusData.data.form[item].label,
                  statusData.data.form[item].required,
                  `${item} ${key}`,
                  item,
                  statusData.data.form[item].options || [],
                );
              })
            : null}
          <Box direction="row" justify="between" align="center">
            <Button type="submit" primary label="Apply Changes" />
            <Button secondary label="Cancel" onClick={() => changeStatusFlag(false)} />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
