import { Dispatch } from "redux";
import { apiConstants } from "../_constants";
import { Alert } from ".";
import { history, fetchRequest } from "../_helpers";
import { IAuthenticationAction, IAlertAction } from "../interfaces/Actions";
import { Backend } from "../services/Backend";

export class Authentication {
  private preventLogin = (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
    const alert = new Alert();
    dispatch(alert.fail("Login Failure. Please Login with correct credentials"));
  };

  toLogin = (email: string, password: string, backend: Backend, location: any) => {
    return async (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
      try {
        const response = await fetchRequest(
          "POST",
          `${apiConstants.API_LOGIN}`,
          { "Content-Type": "application/json" },
          { email, password },
        );
        if (response.success) {
          await backend.auth.setPersistence(backend.Persistence);
          await backend.auth.signInWithCustomToken(response.data.token);
          if (location.state && location.state.from) {
            window.location.assign(location.state.from.pathname);
          } else {
            history.push("/");
          }
        } else {
          throw new Error();
        }
      } catch (fail) {
        this.preventLogin(dispatch);
      }
    };
  };

  resetPassword = (email: string, oldPassword: string, newPassword: string) => {
    return async (dispatch: Dispatch<IAuthenticationAction | IAlertAction>) => {
      const alert = new Alert();
      try {
        const response = await fetchRequest(
          "POST",
          `${apiConstants.API_RESET_PASSWORD}`,
          { "Content-Type": "application/json" },
          { email, oldPassword, newPassword },
        );
        if (response.success) {
          history.push("/");
          dispatch(alert.pass("Password Changed"));
        } else {
          throw new Error();
        }
      } catch (fail) {
        dispatch(alert.fail("Wrong credentials"));
      }
    };
  };
}
