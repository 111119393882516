// eslint-disable-next-line
import React from "react";
import { Box } from "grommet";

import { Logo } from "../Logo";
import { NavItem } from "./NavItem";
import { Backend } from "../../services/Backend";

export function NavBar({ backend }: { backend: Backend }) {
  return (
    <Box
      align="center"
      justify="start"
      direction="row"
      pad={{ vertical: "xsmall", horizontal: "small" }}
      background={{ color: "brand" }}
      elevation="small"
      width={{ min: "1100px" }}
    >
      <Logo />
      <NavItem backend={backend} />
    </Box>
  );
}
