import { alertConstants } from "../_constants";
import { createNotification } from "../_helpers";

export class Alert {
  pass = (message: string) => {
    createNotification("success", message);
    return {
      type: alertConstants.SUCCESS,
      message,
    };
  };

  fail = (message: string) => {
    createNotification("error", message);
    return {
      type: alertConstants.ERROR,
      message,
    };
  };

  clean = () => {
    return {
      type: alertConstants.CLEAR,
    };
  };
}
